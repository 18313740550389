<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: 'bg-blue-400',
  },
});
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <TnHeader />
    <div class="flex items-center justify-center py-10">
      <TnHeading>PRODUCTS</TnHeading>
    </div>
    <div class="bg-beige-100 flex-grow pb-6">
      <div class="h-[12px] bg-[url('/assets/pink-stripped-pattern.svg')]"></div>
      <div class="mx-auto max-w-7xl">
        <slot />
      </div>
    </div>

    <TnFooter class="mt-auto" />
  </div>
</template>
